import axios from '@axios'

export default {
  namespaced: true,
  state: {
    selectedLesson: null,
    isLoading: false,
  },
  getters: {
    getSelectedLesson: state => state.selectedLesson,
    getIsLoading: state => state.isLoading,
  },
  mutations: {
    SET_SELECTED_LESSON: (state, val) => {
      state.selectedLesson = val
    },
    SET_IS_LOADING: (state, val) => {
      state.isLoading = val
    },
  },
  actions: {
    fetchLessonsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/academics/schedule/lessons', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
