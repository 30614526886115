<template>
  <validation-observer
    ref="exportGradebookModalRules"
  >
    <b-modal
      id="exportGradebookModal"
      ref="exportGradebookModal"
      title="Export Gradebook"
      centered
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      ok-only
      ok-title="Export"
      :ok-disabled="isLoading"
      @ok="handleForm"
      @hidden="resetData"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >

        <b-form-group
          label="Export type"
          class="mb-2"
        >
          <validation-provider
            #default="{ errors }"
            name="Export type"
            rules="required"
          >
            <v-select
              v-model="exportType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="exportOptions"
              :clearable="false"
              :reduce="option => option.value"
              placeholder="Select Export Type"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-overlay>
    </b-modal>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BModal,
  BOverlay,
  BFormGroup,
} from 'bootstrap-vue'
import { downloadExportFile } from '@core/mixins/downloadExportFile'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import useGradebook from '../useGradebook'

export default {
  name: 'ExportGradebookModal',
  components: {
    BModal,
    BOverlay,
    BFormGroup,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  mixins: [downloadExportFile],
  data() {
    return {
      isLoading: false,
      exportType: null,
      exportOptions: [
        {
          label: 'Export CSV',
          value: 'csv',
        },
        {
          label: 'Export PDF',
          value: 'pdf',
        },
      ],
      required,
    }
  },
  setup() {
    const {
      exportPdf,
      exportCsv,
      classId,
      subjectId,
    } = useGradebook()

    return {
      exportPdf,
      exportCsv,
      classId,
      subjectId,
    }
  },
  computed: {
    ...mapGetters({
      classDetails: 'app-subject-details/getClassDetails',
    }),
  },
  methods: {
    handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.exportGradebookModalRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.handleExportGradebook()
          } else {
            reject()
          }
        })
      })
    },
    async handleExportGradebook() {
      this.isLoading = true
      const queryParams = {
        subject_id: this.subjectId,
        group_id: this.classId,
        final_grade_type: localStorage.getItem('finalGradetype') || '1-100',
      }

      if (this.exportType === 'csv') {
        await this.exportCsv(queryParams)
          .then(response => {
            if (response.status === 200) {
              this.downloadFile(response.data, `${this.classDetails.name}(${this.classDetails.subject.name})`, 'csv')
            }
          })
          .finally(() => {
            this.isLoading = false
            this.closeModal()
          })
      } else {
        await this.exportPdf(queryParams)
          .then(response => {
            if (response.status === 200) {
              this.downloadFile(response.data, `${this.classDetails.name}(${this.classDetails.subject.name})`, 'pdf')
            }
          })
          .finally(() => {
            this.isLoading = false
            this.closeModal()
          })
      }
    },
    resetData() {
      this.exportType = null
    },
    closeModal() {
      this.$bvModal.hide('exportGradebookModal')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
