<template>
  <ValidationObserver
    ref="assignTeacherModal"
  >
    <b-modal
      id="assign-teacher-modal"
      ref="assign-teacher-modal"
      title="Assign Teacher"
      centered
      no-close-on-backdrop
      ok-title="Assign Teacher"
      :ok-disabled="isLoading"
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      @show="setModalData"
      @ok="handleForm"
      @hidden="resetModal"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-form-group
          label="Teacher"
          class="mb-2"
        >
          <validation-provider
            #default="{ errors }"
            name="Teacher"
            rules="required"
          >
            <v-select
              v-model="selectedTeacher"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="teachersList"
              :clearable="false"
              :reduce="item => item.id"
              placeholder="Select Teacher"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-overlay>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BOverlay,
  BFormGroup,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import useClassesList from '@/views/admin/academics/classes-list/useClassesList'

export default {
  name: 'AssignTeacherModal',
  components: {
    BModal,
    BOverlay,
    BFormGroup,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    selectedClassId: {
      type: String || null,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedTeacher: null,
      teachersList: [],
      required,
    }
  },
  computed: {
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
  },
  setup() {
    const {
      fetchTeachersList,
      assignTeacher,
    } = useClassesList()

    return {
      fetchTeachersList,
      assignTeacher,
    }
  },
  created() {
    this.prepareTeachersList()
  },
  methods: {
    async prepareTeachersList() {
      const queryParams = {
        program_id: this.programId,
      }
      await this.fetchTeachersList(queryParams)
        .then(response => {
          this.teachersList = response.map(item => ({ id: item.user.id, label: item.user.full_name }))
        })
    },
    handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.assignTeacherModal.validate().then(success => {
          if (success) {
            resolve(true)
            this.handleAssignTeacher()
          } else {
            reject()
          }
        })
      })
    },
    async handleAssignTeacher() {
      this.isLoading = true
      const queryParams = {
        ...this.subject,
        program_id: this.programId,
        teacher_id: this.selectedTeacher,
        class_id: this.selectedClassId,
      }

      await this.assignTeacher(queryParams)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
          this.$bvModal.hide('assign-teacher-modal')
        })
    },
    setModalData() {
      this.selectedTeacher = this.subject.teacher_id || null
    },
    resetModal() {
      this.selectedTeacher = null
    },
  },
}
</script>

<style>

</style>
