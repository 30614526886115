<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-card>
        <div class="d-flex justify-content-between align-items-center flex-sm-row flex-column">
          <h3 class="pb-1">
            {{ classDetails.name }} <span class="text-muted">({{ classDetails.students_amount }} {{ classDetails.students_amount == 1 ? camperStudent(1) : camperStudent(2) }})</span>
          </h3>
          <b-button
            v-if="$can('assign-teacher', permissionSubjects.Academics)"
            v-b-modal.assign-teacher-modal
            class="ml-1"
            variant="primary"
          >
            <span
              class="ml-25 align-middle"
            >Assign Teacher</span>
          </b-button>
        </div>

        <b-row>
          <b-col
            sm="12"
            md="4"
          >
            <b-card
              v-if="classDetails.subject"
              class="subject-item"
            >
              <b-col
                cols="12"
                class="subject-card"
              >
                <div>
                  <h5>{{ classDetails.subject.name }}</h5>
                  <h6>Teacher: {{ classDetails.subject.teacher_name }}</h6>
                </div>
                <h5 class="text-muted">
                  {{ classDetails.students_amount }} {{ classDetails.students_amount == 1 ? camperStudent(1) : camperStudent(2) }}
                </h5>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <!-- Table Container Card -->
    <students-list
      @refetchData="prepareClassDetails"
    />

    <assign-teacher-modal
      v-if="currentClassId && classDetails.subject && $can('assign-teacher', permissionSubjects.Academics)"
      :selected-class-id="currentClassId"
      :subject="classDetails.subject"
      @success="prepareClassDetails"
    />
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  BButton,
} from 'bootstrap-vue'

import useClassDetails from '../../../class-details/useClassDetails'
import AssignTeacherModal from '../../modals/AssignTeacherModal.vue'
import StudentsList from '../../../class-details/StudentsList.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'ClassOverviewTab',
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,

    AssignTeacherModal,
    StudentsList,
  },
  setup() {
    const {
      fetchStudentsList,
      tableColumns,
      isLoading,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refClassStudentsTable,

      refetchData,
      fetchClassDetails,
      currentClassId,
      currentSubjectId,
    } = useClassDetails()

    return {
      fetchStudentsList,
      tableColumns,
      isLoading,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refClassStudentsTable,

      refetchData,
      fetchClassDetails,
      currentClassId,
      currentSubjectId,
    }
  },
  data() {
    return {
      permissionSubjects,
    }
  },
  computed: {
    ...mapGetters({
      classDetails: 'app-subject-details/getClassDetails',
    }),
  },
  methods: {
    async prepareClassDetails() {
      const response = await this.fetchClassDetails()
      const payloadData = {
        ...response,
        // eslint-disable-next-line eqeqeq
        subject: response.subjects.find(item => item.id == this.currentSubjectId),
      }
      this.$store.commit('app-subject-details/SET_CLASS_DETAILS', payloadData)
    },
  },
}
</script>`

<style lang="scss" scoped>
  .subject-item {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12) !important;
    border-radius: 8px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .subject-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 769px) {
    .subject-card {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
    }
  }
</style>
