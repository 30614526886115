<template>
  <div>
    <div v-if="!selectedLesson">
      <b-card>
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-2">
            Filters
          </h3>
        </div>

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="w-100 mb-2"
          >
            <v-select
              v-model="filterStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :clearable="false"
              :placeholder="'Select Status'"
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="w-100 mb-2"
          >
            <flat-pickr
              v-model="filterDate"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', mode: 'range'}"
              :placeholder="'Select Date/Time'"
            />
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <div class="d-flex align-items-center">
              <b-button
                variant="primary"
                @click="clearFilters"
              >
                <span class="mr-25 align-middle">Reset filters</span>
              </b-button>
            </div>
          </b-col>

        </b-row>
      </b-card>

      <!-- Table Container Card -->
      <b-card
        no-body
      >

        <div class="table-header m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label class="mr-1 mb-0">Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                :reduce="option => option.value"
                class="per-page-selector d-inline-block ml-50 mr-1 m-pagination"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="6"
            >
              <div class="d-flex align-items-center justify-content-end">

                <b-button
                  v-if="$can('create-lesson', permissionSubjects.Academics)"
                  aria-controls="sidebar-add-new-event"
                  :aria-expanded="String(isEventHandlerSidebarActive)"
                  variant="primary"
                  @click="isEventHandlerSidebarActive = true"
                >
                  Add Lesson
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <div>
            <b-table
            ref="refLessonsListTable"
            :items="fetchLessonsList"
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative table-responsive-sm"
          >
            <!-- Loader -->
            <template #table-busy>
              <TableSpinner />
            </template>

            <!-- Column: date -->
            <template #cell(date)="data">
              <span
                class="text-primary cursor-pointer"
                @click="selectLesson(data.item)"
              >
                {{ data.item.start | dateToDetailString }}
              </span>
            </template>

            <!-- Column: tz -->
            <template #cell(timezone)="data">
              <span
                class="text-primary cursor-pointer"
                @click="selectLesson(data.item)"
              >
                {{ data.item.start | dateToTZ }}
              </span>
            </template>

            <!-- Column: start -->
            <template #cell(start)="data">
              <span
                class="text-primary cursor-pointer"
                @click="selectLesson(data.item)"
              >
                {{ data.item.start | dateToTime }}
              </span>
            </template>

            <!-- Column: start -->
            <template #cell(duration)="data">
              <span
                class="text-primary cursor-pointer"
                @click="selectLesson(data.item)"
              >
                {{ data.item.extendedProps.duration }}
              </span>
            </template>

            <!-- Column: end -->
            <template #cell(end)="data">
              <span
                class="text-primary cursor-pointer"
                @click="selectLesson(data.item)"
              >
                {{ data.item.end | dateToTime }}
              </span>
            </template>

            <!-- Column: Teacher -->
            <template #cell(teacher)="data">
              <v-select
                v-model="data.item.extendedProps.teacher_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="teachersList"
                :reduce="item => item.id"
                :clearable="false"
                :disabled="!$can('assign-teacher', permissionSubjects.Academics)"
                @input="updateLessonTeacher(data.item)"
              />
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-dropdown
                variant="link"
                toggle-class="mx-auto p-0"
                no-caret
                right
              >

                <template #button-content>
                  <b-badge
                    pill
                    :variant="`light-${resolveStatusVariant(data.item.extendedProps.status)}`"
                  >
                    {{ data.item.extendedProps.status }}
                  </b-badge>
                </template>
                <b-dropdown-item
                  v-for="(status, index) in statusOptions"
                  :key="index"
                  @click="changeLessonStatus(data.item.id, status)"
                >
                  <b-badge
                    pill
                    class="w-100"
                    :variant="`light-${resolveStatusVariant(status)}`"
                  >
                    {{ status }}
                  </b-badge>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <!-- Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="mx-auto p-0"
                no-caret
                right
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="$can('update-subject', permissionSubjects.Academics)"
                  @click="handleEditLesson(data.item)"
                >
                  <span>Edit lesson</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('delete-lesson', permissionSubjects.Academics)"
                  @click="deleteLesson(data.item.id)"
                >
                  <span>Delete lesson</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>

          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-if="totalItems && perPage !== 'all'"
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>

        </div>

      </b-card>
    </div>

    <lesson-attendence v-else />

    <div
      class="body-content-overlay"
      :class="{'show': isCalendarOverlaySidebarActive}"
      @click="isCalendarOverlaySidebarActive = false"
    />
    <calendar-event-handler
      v-if="$can('create-lesson' || 'update-lesson', permissionSubjects.Academics)"
      v-model="isEventHandlerSidebarActive"
      :event="event"
      :clear-event-data="clearEventData"
      @remove-event="deleteLesson(event.id)"
      @add-event="addLesson"
      @update-event="updateLesson"
      @update-recurrent-event="updateRecurrentLessons"
    />
  </div>

</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
  BBadge,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { filters } from '@core/mixins/filters'
import flatPickr from 'vue-flatpickr-component'

import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import useLessonsList from './useLessonsList'
import useClassesList from '@/views/admin/academics/classes-list/useClassesList'

import storeModule from './lessonsStoreModule'
import store from '@/store'
import LessonAttendence from './LessonAttendence.vue'
import CalendarEventHandler from '../schedule/calendar-event-handler/CalendarEventHandler.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'LessonsList',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,

    TableSpinner,
    vSelect,
    flatPickr,
    LessonAttendence,
    CalendarEventHandler,
  },
  mixins: [filters],
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-lessons'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchLessonsList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      filterDate,
      filterStatus,
      sortBy,
      totalItems,
      isSortDirDesc,
      refLessonsListTable,
      statusOptions,

      refetchData,
      clearFilters,
      teachersList,
      programId,
      resolveStatusVariant,
      addLesson,
      updateLesson,
      updateRecurrentLessons,
      deleteLesson,
      changeLessonStatus,
      event,
      clearEventData,
      isCalendarOverlaySidebarActive,
      isEventHandlerSidebarActive,
      handleEditLesson,
      grabEventDataFromTableRow,
    } = useLessonsList()

    const {
      fetchTeachersList,
    } = useClassesList()

    return {
      fetchTeachersList,
      fetchLessonsList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      filterDate,
      filterStatus,
      sortBy,
      totalItems,
      isSortDirDesc,
      refLessonsListTable,
      statusOptions,

      refetchData,
      clearFilters,
      teachersList,
      programId,
      resolveStatusVariant,
      addLesson,
      updateLesson,
      updateRecurrentLessons,
      deleteLesson,
      changeLessonStatus,
      event,
      clearEventData,
      isCalendarOverlaySidebarActive,
      isEventHandlerSidebarActive,
      handleEditLesson,
      grabEventDataFromTableRow,
    }
  },
  data() {
    return {
      permissionSubjects,
    }
  },
  computed: {
    ...mapGetters({}),
    selectedLesson: {
      get() {
        if (store.getters['app-subject-details/getLessonDetails']) {
          return store.getters['app-subject-details/getLessonDetails']
        }
        return store.getters['app-lessons/getSelectedLesson']
      },
    },
  },
  created() {
    this.prepareTeachersList()
  },
  methods: {
    async prepareTeachersList() {
      const queryParams = {
        program_id: this.programId,
      }
      await this.fetchTeachersList(queryParams)
        .then(response => {
          this.teachersList = response.map(item => ({ id: item.user.id, label: item.user.full_name }))
        })
    },
    selectLesson(lesson) {
      this.$store.commit('app-lessons/SET_SELECTED_LESSON', lesson)
    },
    updateLessonTeacher(lesson) {
      const queryParams = this.grabEventDataFromTableRow(lesson)
      this.updateLesson(queryParams)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

@media (max-width: 769px) {
  .m-pagination .vs__selected-options {
    .vs__search {
      display: none !important;
    }
  }
}
</style>
