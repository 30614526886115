import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useLessonsList() {
  // Use toast
  const toast = useToast()

  const refLessonsListTable = ref(null)

  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])
  const classId = router.currentRoute.params.id
  const { subjectId } = router.currentRoute.params

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  const blankEvent = {
    start: '',
    end: '',
    duration: '',
    startTime: '',
    startRecur: '',
    endRecur: '',
    daysOfWeek: [],
    extendedProps: {
      weekly_recurring: false,
      program_id: programId.value,
      group_id: classId,
      subject_id: subjectId,
      teacher_id: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  const grabEventDataFromTableRow = eventData => {
    const {
      id,
      start,
      end,
      groupId,
      extendedProps: {
        duration,
        schedule_event,
        weekly_recurring,
        program_id,
        group_id,
        subject_id,
        teacher_id,
      },
    } = eventData

    const event = {
      id,
      start,
      end,
      duration,
      groupId,
      extendedProps: {
        schedule_event,
        weekly_recurring,
        program_id,
        group_id,
        subject_id,
        teacher_id,
      },
    }

    // eslint-disable-next-line camelcase
    if (schedule_event) {
      event.startTime = schedule_event.start_time
      event.startRecur = schedule_event.start_recurring
      event.endRecur = schedule_event.end_recurring
      event.daysOfWeek = schedule_event.days_of_week
    }

    return event
  }

  const handleEditLesson = clickedEvent => {
    event.value = grabEventDataFromTableRow(clickedEvent)

    // eslint-disable-next-line no-use-before-define
    isEventHandlerSidebarActive.value = true
  }

  // Table Handlers
  const tableColumns = [
    { key: 'date', label: 'Lesson date', sortable: true },
    { key: 'timezone', label: 'Timezone', sortable: false },
    { key: 'start', label: 'Start time' },
    { key: 'duration', label: 'Duration', sortable: true },
    { key: 'end', label: 'End Time' },
    { key: 'teacher', label: 'Teacher', sortable: true },
    { key: 'status', label: 'Status', sortable: true },
    { key: 'actions', class: 'table-actions' },
  ]

  const studentsList = ref([])
  const perPage = ref(DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const filterDate = ref('')
  const filterStatus = ref('')
  const statusOptions = [
    'Completed', 'Scheduled', 'Canceled',
  ]
  const attendenceStatusOptions = [
    {
      label: 'Unmarked',
      value: null,
    },
    {
      label: 'On time',
      value: 'on time',
    },
    {
      label: 'Late',
      value: 'late',
    },
    {
      label: 'Excused',
      value: 'excused',
    },
    {
      label: 'Absent',
      value: 'absent',
    },
  ]
  const sortBy = ref('date')
  const isSortDirDesc = ref(false)
  const teachersList = ref([])

  const clearFilters = () => {
    filterDate.value = ''
    filterStatus.value = ''
  }

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const refetchData = () => {
    if (refLessonsListTable) {
      refLessonsListTable.value.refresh()
    }
  }

  watch([currentPage, perPage, filterDate, filterStatus], () => {
    refetchData()
  })

  const fetchLessonsList = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value ? sortBy.value : 'start',
      sortDesc: isSortDirDesc.value,
      program_id: programId.value,
      group_id: classId,
      subject_id: subjectId,
    }
    if (filterDate.value) {
      payloadData.date = filterDate.value
    }
    if (filterStatus.value) {
      payloadData.status = filterStatus.value
    }

    store
      .dispatch('app-lessons/fetchLessonsList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta

        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching lessons' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const addLesson = lessonData => axios
    .post('/auth/academics/schedule/lessons', lessonData)
    .then(() => {
      refetchData()
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating new Lesson',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const changeLessonStatus = (lessonId, status) => axios
    .post(`/auth/academics/schedule/lessons/${lessonId}/status`, { status })
    .then(() => {
      refetchData()
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating status',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateLesson = (lessonData, withRefresh = true) => axios
    .put(`/auth/academics/schedule/lessons/${lessonData.id}`, lessonData)
    .then(response => {
      if (withRefresh) {
        refetchData()
      }
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Lesson',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateRecurrentLessons = (lessonData, withRefresh = true) => axios
    .put(`auth/academics/schedule/schedule-event/${lessonData.groupId}`, lessonData)
    .then(response => {
      if (withRefresh) {
        refetchData()
      }

      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Lesson',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteLesson = (id, withRefresh = true) => axios
    .delete(`/auth/academics/schedule/lessons/${id}`)
    .then(() => {
      if (withRefresh) {
        refetchData()
      }
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error deleting Lesson',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchStudentsList = queryParam => axios
    .get('auth/academics/attendance/students', {
      params: queryParam,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching students list',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const changeLessonAttendance = queryParams => axios
    .post('auth/academics/attendance/set-status', queryParams)
    .then(() => {
      // refetchData()
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Attendance',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'Completed') return 'success'
    if (status === 'Canceled') return 'danger'
    if (status === 'Scheduled') return 'primary'
    return 'primary'
  }

  const resolveAttendenceStudentStatus = status => {
    const studentStatus = attendenceStatusOptions.find(item => item.value === status)
    return studentStatus.label
  }

  const resolveAttendenceStatusVariant = status => {
    if (status === 'reset') return 'secondary'
    if (status === 'on time') return 'success'
    if (status === 'late') return 'warning'
    if (status === 'excused') return 'primary'
    if (status === 'absent') return 'danger'
    return 'primary'
  }
  return {
    fetchLessonsList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    filterDate,
    filterStatus,
    sortBy,
    totalItems,
    isSortDirDesc,
    refLessonsListTable,
    statusOptions,

    refetchData,
    clearFilters,
    teachersList,
    programId,
    resolveStatusVariant,
    resolveAttendenceStatusVariant,
    resolveAttendenceStudentStatus,
    fetchStudentsList,
    studentsList,
    attendenceStatusOptions,
    addLesson,
    event,
    clearEventData,
    updateLesson,
    updateRecurrentLessons,
    deleteLesson,
    changeLessonStatus,
    isEventHandlerSidebarActive,
    isCalendarOverlaySidebarActive,
    handleEditLesson,
    changeLessonAttendance,
    grabEventDataFromTableRow,
  }
}
