import { ref } from '@vue/composition-api'
import axios from '@axios'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useGradebook() {
  // Use toast
  const toast = useToast()

  const isLoading = ref(false)
  const refGradebookListTable = ref(null)

  const classId = router.currentRoute.params.id
  const { subjectId } = router.currentRoute.params

  // Table Handlers
  const tableColumns = ref([
    {
      key: 'full_name', stickyColumn: true, label: 'Student', isEditable: false,
    },
  ])
  const studentsWithGrades = ref([])
  const gradebookModalMode = ref('create')
  const selectedGradeForEdit = ref(null)
  const gradebookEntriesList = ref([])

  const refetchData = () => {
    refGradebookListTable.value.refresh()
  }

  const fetchStudentsWithGrades = queryParam => axios
    .get('/auth/academics/gradebook/students', {
      params: queryParam,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Gradebook',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const setGradeForStudent = queryParams => axios
    .post('/auth/academics/gradebook/set-grade', queryParams)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating students grade',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateStudentsPosition = queryParams => axios
    .post('/auth/academics/gradebook/update-students-position', queryParams)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating students position',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchGradebookEntriesList = queryParam => axios
    .get('/auth/academics/grades', {
      params: queryParam,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: "Error fetching Gradebook Entries' list",
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const addGradebookEntry = queryParams => axios
    .post('/auth/academics/grades', queryParams)
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating new Gradebook Entry',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateGradebookEntry = queryParams => axios
    .put(`auth/academics/grades/${queryParams.id}`, queryParams)
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Gradebook Entry',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteGradebookEntry = id => axios
    .delete(`/auth/academics/grades/${id}`)
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error deleting Gradebook Entry',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const exportPdf = queryParams => axios
    .post('/auth/academics/gradebook/export-pdf', queryParams, { responseType: 'blob' })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting Gradebook',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const exportCsv = queryParams => axios
    .post('/auth/academics/gradebook/export-csv', queryParams, { responseType: 'blob' })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting Gradebook',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    isLoading,
    tableColumns,
    refGradebookListTable,
    studentsWithGrades,
    fetchStudentsWithGrades,
    setGradeForStudent,
    updateStudentsPosition,
    fetchGradebookEntriesList,
    addGradebookEntry,
    updateGradebookEntry,
    deleteGradebookEntry,
    exportPdf,
    exportCsv,
    refetchData,
    gradebookModalMode,
    selectedGradeForEdit,
    gradebookEntriesList,
    classId,
    subjectId,
  }
}
