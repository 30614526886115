// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import moment from '@/libs/moment'

export default function userCalendar() {
  // Use toast
  const toast = useToast()

  const isLoading = ref(false)
  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])
  const classDetails = computed(() => store.getters['app-subject-details/getClassDetails'])
  const classId = router.currentRoute.params.id
  const { subjectId } = router.currentRoute.params

  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    if (refCalendar.value) {
      calendarApi = refCalendar.value.getApi()
    }
  })

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    if (calendarApi) {
      calendarApi.refetchEvents()
    }
  }
  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    start: '',
    end: '',
    duration: '',
    startTime: '',
    startRecur: classDetails.value.session_start,
    endRecur: classDetails.value.session_end,
    daysOfWeek: [],
    extendedProps: {
      weekly_recurring: false,
      program_id: programId.value,
      group_id: classId,
      subject_id: subjectId,
      teacher_id: classDetails.value.subject.teacher_id,
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      start,
      end,
      groupId,
      extendedProps: {
        duration,
        schedule_event,
        weekly_recurring,
        program_id,
        group_id,
        subject_id,
        teacher_id,
      },
    } = eventApi

    const event = {
      id,
      start,
      end,
      duration,
      groupId,
      extendedProps: {
        schedule_event,
        weekly_recurring,
        program_id,
        group_id,
        subject_id,
        teacher_id,
      },
    }

    // eslint-disable-next-line camelcase
    if (schedule_event) {
      event.startTime = schedule_event.start_time
      event.startRecur = schedule_event.start_recurring
      event.endRecur = schedule_event.end_recurring
      event.daysOfWeek = schedule_event.days_of_week
    }
    return event
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = eventData => {
    store.dispatch('calendar/addEvent', { event: eventData })
      .then(() => {
        // eslint-disable-next-line no-use-before-define
        refetchEvents()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error creating calendar events',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = eventData => {
    const queryParams = {
      ...eventData,
      start: moment(eventData.start).format('YYYY-MM-DD h:mm a'),
    }
    store.dispatch('calendar/updateEvent', { event: queryParams })
      .then(() => {
        // eslint-disable-next-line no-use-before-define
        refetchEvents()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating calendar event',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const updateRecurrentEvent = eventData => {
    store.dispatch('calendar/updateRecurrentEvent', { event: eventData })
      .then(() => {
        // eslint-disable-next-line no-use-before-define
        refetchEvents()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating calendar events',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId })
      .then(() => {
        refetchEvents()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting calendar event',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return

    isLoading.value = true

    const queryParams = {
      program_id: programId.value,
      group_id: classId,
      subject_id: subjectId,
    }
    // Fetch Events from API endpoint
    store
      .dispatch('calendar/fetchEvents', queryParams)
      .then(response => {
        successCallback(response.data.data)
        isLoading.value = false
      })
      .catch(() => {
        isLoading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendar events',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const redirectToLessonTab = eventData => {
    store.commit('app-subject-details/SET_TAB_INDEX', 2)
    store.commit('app-subject-details/SET_LESSON_DETAILS', eventData)
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'timeGridWeek',
    headerToolbar: {
      start: 'prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    events: fetchEvents,
    // timeZone: localStorage.getItem('timezone') || 'local',
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    // editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    // eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    // dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 2,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames() {
      return [
        'bg-light-primary',
      ]
    },
    eventClick({ event: clickedEvent }) {
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"

      // event.value = grabEventDataFromEventApi(clickedEvent)
      // eslint-disable-next-line no-use-before-define
      // isEventHandlerSidebarActive.value = true
      redirectToLessonTab(clickedEvent)
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { startTime: info.date, start: info.date })))
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    // eventDrop({ event: droppedEvent }) {
    //   updateEvent(grabEventDataFromEventApi(droppedEvent))
    // },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    // eventResize({ event: resizedEvent }) {
    //   updateEvent(grabEventDataFromEventApi(resizedEvent))
    // },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    isLoading,
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    updateRecurrentEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,
    grabEventDataFromEventApi,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
