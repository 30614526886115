import { ref } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useSubjectDetails() {
  // Use toast
  const toast = useToast()

  const currentClassId = router.currentRoute.params.id
  const currentSubjectId = router.currentRoute.params.subjectId

  const isLoading = ref(false)

  const fetchClassDetails = () => {
    isLoading.value = true
    return axios
      .get(`/auth/academics/view/${currentClassId}`)
      .then(response => response.data.data)
      .catch(() => toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Class item',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      }))
      .finally(() => {
        isLoading.value = false
      })
  }

  return {
    isLoading,
    fetchClassDetails,
    currentClassId,
    currentSubjectId,
  }
}
