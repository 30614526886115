<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      no-enforce-focus
      lazy
      @change="toggleSidebar"
      @hidden="updateOnlyCurrent = true"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventLocal.id ? 'Update': 'Add' }} Lesson
          </h5>
          <div>
            <feather-icon
              v-if="eventLocal.id && $can('delete-lesson', permissionSubjects.Academics)"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Update only current event -->
            <b-form-group
              v-if="eventLocal.id && eventLocal.extendedProps.weekly_recurring"
            >
              <b-form-checkbox
                v-model="updateOnlyCurrent"
                name="check-button"
                switch
                inline
              >
                Update only current event
              </b-form-checkbox>
            </b-form-group>

            <!-- Weekly Recurring -->
            <b-form-group
              v-if="!eventLocal.id"
            >
              <b-form-checkbox
                v-model="eventLocal.extendedProps.weekly_recurring"
                name="check-button"
                switch
                inline
              >
                Weekly
              </b-form-checkbox>
            </b-form-group>

            <!-- Start -->
            <validation-provider
              #default="validationContext"
              name="Start Date"
              :rules="{ required: !isWeekly}"
            >
              <b-form-group
                v-show="!isWeekly"
                label="Start Date"
                label-for="start"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  id="start"
                  v-model="eventLocal.start"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d h:i K',
                  }"
                  placeholder="Start Date"
                  name="start"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Start Time -->
            <validation-provider
              #default="validationContext"
              name="Start Time"
              :rules="{ required: isWeekly}"
            >
              <b-form-group
                v-show="isWeekly"
                label="Start Time"
                label-for="start-time"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.startTime"
                  class="form-control"
                  :config="{ noCalendar: true, enableTime: true, dateFormat: 'h:i K'}"
                  placeholder="Start Time"
                  name="start-time"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- duration -->
            <validation-provider
              #default="validationContext"
              name="Duration"
              rules="required"
            >
              <b-form-group
                label="Duration"
                label-for="duration"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.duration"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="durationOptions"
                  :clearable="false"
                  placeholder="Select Duration"
                  input-id="duration"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Teacher -->
            <validation-provider
              #default="validationContext"
              name="Teacher"
              rules="required"
            >
              <b-form-group
                label="Teacher"
                label-for="add-teacher"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.teacher_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="teachersOptions"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select Teacher"
                  input-id="add-teacher"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Days of the week -->
            <validation-provider
              #default="validationContext"
              name="Days of the week"
              :rules="{ required: isWeekly}"
            >
              <b-form-group
                v-show="isWeekly"
                label="Days of the week"
                label-for="days-of-week"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.daysOfWeek"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :close-on-select="false"
                  :options="daysOfWeekOptions"
                  multiple
                  :reduce="item => item.value"
                  placeholder="Select Days"
                  input-id="days-of-week"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              name="Start Date"
              :rules="{ required: isWeekly}"
            >

              <b-form-group
                v-show="isWeekly"
                label="Start Date"
                label-for="start-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  id="start-date"
                  v-model="eventLocal.startRecur"
                  class="form-control"
                  placeholder="Select Start Date"
                  :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                  name="start-date"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider
              #default="validationContext"
              name="End Date"
              :rules="{ required: isWeekly}"
            >

              <b-form-group
                v-show="isWeekly"
                label="End Date"
                label-for="end-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  id="end-date"
                  v-model="eventLocal.endRecur"
                  class="form-control"
                  placeholder="Select End Date"
                  :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                  name="end-date"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ eventLocal.id ? 'Update' : 'Add ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormCheckbox, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, computed, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      permissionSubjects,
    }
  },
  setup(props, { emit }) {
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,

      // UI
      durationOptions,
      daysOfWeekOptions,
      teachersOptions,
      fetchTeachersList,
      onSubmit,
      programId,
      updateOnlyCurrent,
      toggleSidebar,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    const isWeekly = computed(() => {
      if (eventLocal.value.id) {
        return eventLocal.value.extendedProps.weekly_recurring && !updateOnlyCurrent.value
      }
      return eventLocal.value.extendedProps.weekly_recurring
    })

    fetchTeachersList()

    return {
      // Add New Event
      eventLocal,
      onSubmit,
      durationOptions,
      daysOfWeekOptions,
      teachersOptions,
      fetchTeachersList,
      programId,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
      isWeekly,
      updateOnlyCurrent,
      toggleSidebar,
    }
  },
  mounted() {
    // this.$root.$on('bv::modal::shown', bvEvent => {
    //   bvEvent.vueTarget.$refs.content.attributes.removeNamedItem('tabindex')
    // })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
