<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Calendar -->
      <b-card class="col position-relative">
        <div class="d-flex justify-content-between align-items-center flex-sm-row flex-column">
          <h3 class="pb-1">
            {{ classDetails.name }} <span class="text-muted">({{ classDetails.students_amount }} {{ classDetails.students_amount == 1 ? camperStudent(1) : camperStudent(2) }})</span>
          </h3>
          <b-button
            v-if="$can('create-lesson', permissionSubjects.Academics)"
            aria-controls="sidebar-add-new-event"
            :aria-expanded="String(isEventHandlerSidebarActive)"
            variant="primary"
            @click="isEventHandlerSidebarActive = true"
          >
            Add Lesson
          </b-button>
        </div>
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <b-overlay
              id="overlay-background"
              :show="isLoading"
              variant="white"
              opacity="0.3"
              rounded="sm"
            >
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </b-overlay>
          </div>
        </div>
      </b-card>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-if="$can('create-lesson', permissionSubjects.Academics)"
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
        @update-recurrent-event="updateRecurrentEvent"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BButton,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import calendarStoreModule from './calendarStoreModule'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'Schedule',
  components: {
    BButton,
    BCard,
    BOverlay,
    FullCalendar,
    CalendarEventHandler,
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      isLoading,
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      updateRecurrentEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    fetchEvents()

    return {
      isLoading,
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      updateRecurrentEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
  data() {
    return {
      permissionSubjects,
    }
  },
  computed: {
    ...mapGetters({
      classDetails: 'app-subject-details/getClassDetails',
    }),
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
