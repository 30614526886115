import axios from '@axios'

export default {
  namespaced: true,
  state: {
    classDetails: null,
    tabIndex: 0,
    lessonDetails: null,
  },
  getters: {
    getClassDetails: state => state.classDetails,
    getTabIndex: state => state.tabIndex,
    getLessonDetails: state => state.lessonDetails,
  },
  mutations: {
    SET_CLASS_DETAILS: (state, val) => {
      state.classDetails = val
    },
    SET_LESSON_DETAILS: (state, val) => {
      state.lessonDetails = val
    },
    SET_TAB_INDEX: (state, val) => {
      state.tabIndex = val
    },
  },
  actions: {
    fetchLessonsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/academics/schedule/lessons', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
