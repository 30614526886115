<template>
  <b-overlay
    id="overlay-background"
    :show="!classDetails"
    variant="white"
    opacity="1"
    rounded="sm"
  >
    <div class="d-flex justify-content-between align-items-center header-height mb-1">
      <div
        v-if="classDetails"
        class="d-flex align-items-center"
      >
        <b-link
          :to="{ name: 'admin-class-details', params: { id: classDetails.id } }"
        >
          <h4>{{ classDetails.name }}</h4>
        </b-link>
        <h4>></h4>
        <h4>{{ classDetails.subject.name }}</h4>
      </div>
    </div>
    <b-tabs
      v-if="classDetails"
      v-model="tabIndex"
      pills
      nav-wrapper-class="col-12 px-0"
      nav-class="mb-0 text-center"
    >
      <b-tab
        active
        lazy
      >
        <template #title>
          <span class="font-weight-bold">Class Overview</span>
        </template>

        <class-overview-tab />
      </b-tab>
      <b-tab
        lazy
      >
        <template #title>
          <span class="font-weight-bold">Schedule</span>
        </template>

        <schedule />
      </b-tab>
      <b-tab
        lazy
      >
        <template #title>
          <span class="font-weight-bold">Lessons</span>
        </template>

        <lessons-list />
      </b-tab>
      <b-tab
        lazy
      >
        <template #title>
          <span class="font-weight-bold">Gradebook</span>
        </template>

        <gradebook />
      </b-tab>

    </b-tabs>
  </b-overlay>
</template>

<script>
import {
  BTabs, BTab, BLink, BOverlay,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import ClassOverviewTab from './tabs/class-overview/ClassOverviewTab.vue'

import Schedule from './tabs/schedule/Schedule.vue'
import LessonsList from './tabs/lessons/LessonsList.vue'
import Gradebook from './tabs/gradebook/Gradebook.vue'
import useSubjectDetails from './useSubjectDetails'
import storeModule from './subjectDetailsStoreModule'
import store from '@/store'

export default {
  name: 'SubjectDetails',
  components: {
    BTabs,
    BTab,
    BLink,
    BOverlay,
    ClassOverviewTab,
    Schedule,
    LessonsList,
    Gradebook,
  },
  data() {
    return {}
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-subject-details'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const {
      fetchClassDetails,
      currentClassId,
      currentSubjectId,
    } = useSubjectDetails()

    return {
      fetchClassDetails,
      currentClassId,
      currentSubjectId,
    }
  },
  computed: {
    ...mapGetters({
      classDetails: 'app-subject-details/getClassDetails',
    }),
    tabIndex: {
      get() {
        return store.getters['app-subject-details/getTabIndex']
      },
      set(val) {
        store.commit('app-subject-details/SET_TAB_INDEX', val)
      },
    },
  },
  async created() {
    this.prepareClassDetails()
  },
  methods: {
    async prepareClassDetails() {
      const response = await this.fetchClassDetails()
      const payloadData = {
        ...response,
        // eslint-disable-next-line eqeqeq
        subject: response.subjects.find(item => item.id == this.currentSubjectId),
      }
      store.commit('app-subject-details/SET_CLASS_DETAILS', payloadData)
    },
  },
}
</script>
