<template>
  <validation-observer
    ref="gradebookEntryModalRules"
  >
    <b-modal
      id="gradebookEntryModal"
      ref="gradebookEntryModal"
      :title="title"
      centered
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      :footer-class=" mode == 'edit' ? 'justify-content-between' : 'justify-content-end'"
      @show="setModalData"
      @ok="handleForm"
      @hidden="resetData"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >

        <b-form-group
          label="Grade/Test Name"
        >
          <validation-provider
            #default="{ errors }"
            name="Grade/Test Name"
            rules="required"
          >
            <b-form-input
              v-model="gradebookEntry.title"
              placeholder="Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Grade/Test Date"
        >
          <validation-provider
            #default="{ errors }"
            name="Grade/Test Date"
            rules="required"
          >
            <flat-pickr
              v-model="gradebookEntry.date"
              class="form-control"
              placeholder="Date"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Grade/Test Mark Type"
          class="mb-2"
        >
          <validation-provider
            #default="{ errors }"
            name="Grade/Test Mark Type"
            rules="required"
          >
            <v-select
              v-model="gradebookEntry.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="markTypeOptions"
              :clearable="false"
              placeholder="Select Mark Type"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-overlay>
      <template #modal-footer>
        <SquareIcon
          v-if="mode === 'edit' && $can('delete-grade-entry', permissionSubjects.Academics)"
          size="21"
          icon="Trash2Icon"
          class="cursor-pointer"
          @onIconClick="handleDelete"
        />

        <div>
          <b-button
            variant="outline-secondary"
            @click="closeModal"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="isLoading"
            @click="handleForm"
          >
            {{ buttonTitle }}
          </b-button>
        </div>

      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal,
  BOverlay,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import useGradebook from '../useGradebook'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'GradebookEntryModal',
  components: {
    BModal,
    BOverlay,
    BFormGroup,
    BFormInput,
    BButton,

    vSelect,
    flatPickr,
    SquareIcon,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    mode: {
      type: String,
      default: 'create',
    },
    selectedGradebookEntry: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      gradebookEntry: {
        subject_id: this.subjectId,
        class_id: this.classId,
        title: '',
        date: '',
        type: '',
      },
      markTypeOptions: [
        '1-100', 'A-Z',
      ],
      required,
      permissionSubjects,
    }
  },
  computed: {
    title() {
      return this.mode === 'create' ? 'Create Grade Entry' : 'Edit Grade Entry'
    },
    buttonTitle() {
      return this.mode === 'create' ? 'Create' : 'Update'
    },
  },
  setup() {
    const {
      addGradebookEntry,
      updateGradebookEntry,
      deleteGradebookEntry,
      classId,
      subjectId,
    } = useGradebook()

    return {
      addGradebookEntry,
      updateGradebookEntry,
      deleteGradebookEntry,
      classId,
      subjectId,
    }
  },
  methods: {
    handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.gradebookEntryModalRules.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.mode === 'create') {
              this.handleCreate()
            } else {
              this.handleUpdate()
            }
          } else {
            reject()
          }
        })
      })
    },
    async handleCreate() {
      this.isLoading = true

      await this.addGradebookEntry(this.gradebookEntry)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },
    async handleUpdate() {
      this.isLoading = true

      await this.updateGradebookEntry(this.gradebookEntry)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },
    async handleDelete() {
      this.isLoading = true

      await this.deleteGradebookEntry(this.gradebookEntry.id)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },
    setModalData() {
      if (this.mode === 'create') {
        this.gradebookEntry = {
          subject_id: this.subjectId,
          class_id: this.classId,
          title: '',
          date: '',
          type: '',
        }
      } else if (this.mode === 'edit' && this.selectedGradebookEntry) {
        this.gradebookEntry = JSON.parse(JSON.stringify(this.selectedGradebookEntry))
      }
    },
    resetData() {
      this.gradebookEntry = {
        subject_id: this.subjectId,
        class_id: this.classId,
        title: '',
        date: '',
        type: '',
      }
    },
    closeModal() {
      this.$bvModal.hide('gradebookEntryModal')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
