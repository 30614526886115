import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    fetchEvents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/academics/schedule/lessons', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/academics/schedule/lessons', event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/auth/academics/schedule/lessons/${event.id}`, event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRecurrentEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`auth/academics/schedule/schedule-event/${event.groupId}`, event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/auth/academics/schedule/lessons/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
