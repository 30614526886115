<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="table-header m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Search -->
        <b-col
          cols="12"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              v-if="$can('export-gradebook', permissionSubjects.Academics)"
              v-b-modal.exportGradebookModal
              class="ml-1"
              variant="outline-primary"
            >
              <span
                class="ml-25 align-middle"
              >Export/Print</span>
            </b-button>
            <b-button
              v-if="$can('create-grade-entry', permissionSubjects.Academics)"
              class="ml-1"
              variant="primary"
              @click="showCreateGradebookEntryModal"
            >
              <span
                class="ml-25 align-middle"
              >Create New Grade/Test</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="0.7"
      rounded="sm"
    >
      <b-table
        ref="refGradebookListTable"
        v-sortable="sortableOptions"
        :items="studentsWithGrades"
        :fields="tableColumns"
        responsive
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >
        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <template #head()="data">
          <div
            class="text-nowrap"
          >
            <span>{{ data.label }}</span>
            <feather-icon
              v-if="data.field.isEditable && $can('update-grade-entry', permissionSubjects.Academics)"
              icon="EditIcon"
              size="14"
              class="ml-1 cursor-pointer"
              @click="showEditGradebookEntryModal(data.field.key)"
            />
            <feather-icon
              v-if="data.field.isFinalGrade && $can('update-grade-entry', permissionSubjects.Academics)"
              v-b-modal.editFinalGradeType
              icon="EditIcon"
              size="14"
              class="ml-1 cursor-pointer"
            />
          </div>
        </template>

        <template #cell(full_name)="data">
          <div class="d-flex align-items-center">
            <img
              v-if="data.item.id"
              src="@/assets/images/icons/more-vertical-v2.svg"
              class="handle-drag"
            >
            <span :class="{'font-weight-bold text-right text-uppercase w-100': !data.item.id}">{{ data.value }}</span>
          </div>
        </template>
        <template #cell(0)="data">
          <span class="font-weight-bold">{{ data.value }}</span>
        </template>

        <template #cell()="data">
          <validation-provider
            #default="{ errors }"
            name="Value"
            :rules="data.field.gradeType == '1-100' ? 'integer' : 'alpha'"
          >
            <span
              v-if="!data.item.id"
              class="font-weight-bold"
            >{{ data.value }}</span>
            <b-form-input
              v-else-if="data.field.gradeType == '1-100'"
              v-model="data.value"
              type="number"
              @blur="handlesetGradeForStudent($event.target.value, data.field.key, data.item.id)"
              :disabled="!$can('update-student', permissionSubjects.Academics)"
            />
            <v-select
              v-else-if="data.field.gradeType == 'A-Z'"
              v-model="data.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="alphabeticalOptions"
              :clearable="false"
              append-to-body
              @input="handlesetGradeForStudent($event, data.field.key, data.item.id)"
              :disabled="!$can('update-student', permissionSubjects.Academics)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </template>

      </b-table>
    </b-overlay>

    <gradebook-entry-modal
      :mode="gradebookModalMode"
      :selected-gradebook-entry="selectedGradeForEdit"
      @success="prepareTableData"
    />

    <edit-final-grade-type
      @success="prepareTableData"
    />

    <export-gradebook-modal />
  </b-card>
</template>

<script>
import Sortable from 'sortablejs'

import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { alpha, integer } from '@validations'
import { onUnmounted } from '@vue/composition-api'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import useGradebook from './useGradebook'

import storeModule from './gradebookStoreModule'
import store from '@/store'
import GradebookEntryModal from './modals/GradebookEntryModal.vue'
import EditFinalGradeType from './modals/EditFinalGradeType.vue'
import ExportGradebookModal from './modals/ExportGradebookModal.vue'
import useGradeMappings from '../../../grade-mapping/useGradeMappings'
import { permissionSubjects } from '@/libs/acl/constants'

const createSortable = (el, options) => Sortable.create(el, {
  ...options,
})

const sortable = {
  name: 'sortable',
  bind(el, binding, vnode) {
    const table = el
    // eslint-disable-next-line no-underscore-dangle
    table._sortable = createSortable(table.querySelector('tbody'), binding.value, vnode)
  },
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BFormInput,
    BOverlay,

    vSelect,

    TableSpinner,
    GradebookEntryModal,
    EditFinalGradeType,
    ExportGradebookModal,

    ValidationProvider,
  },
  directives: { sortable },
  data() {
    return {
      alpha,
      integer,
      sortableOptions: {
        handle: '.handle-drag',
        chosenClass: 'is-selected',
        onEnd: this.handleDragEnd,
      },
      alphabeticalOptions: [],
      permissionSubjects,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-gradebook'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      isLoading,
      tableColumns,
      refGradebookListTable,
      studentsWithGrades,
      fetchStudentsWithGrades,
      setGradeForStudent,
      updateStudentsPosition,
      fetchGradebookEntriesList,
      refetchData,
      gradebookModalMode,
      selectedGradeForEdit,
      gradebookEntriesList,
      classId,
      subjectId,
    } = useGradebook()

    const { fetchGradeMappingsList } = useGradeMappings()

    return {
      isLoading,
      tableColumns,
      refGradebookListTable,
      studentsWithGrades,
      fetchStudentsWithGrades,
      setGradeForStudent,
      updateStudentsPosition,
      fetchGradebookEntriesList,
      refetchData,
      gradebookModalMode,
      selectedGradeForEdit,
      gradebookEntriesList,
      classId,
      subjectId,
      fetchGradeMappingsList,
    }
  },
  async created() {
    this.prepareTableData()
    this.prepareAlphabeticalOptions()
  },
  methods: {
    async prepareGradebookEntriesList() {
      const queryParams = {
        class_id: this.classId,
        subject_id: this.subjectId,
      }
      this.gradebookEntriesList = await this.fetchGradebookEntriesList(queryParams)
    },
    async prepareGradebookTable() {
      try {
        this.isLoading = true
        const queryParams = {
          group_id: this.classId,
          subject_id: this.subjectId,
          final_grade_type: localStorage.getItem('finalGradetype') || '1-100',
        }
        const response = await this.fetchStudentsWithGrades(queryParams)

        const studentsWithGrades = []
        const tableColumns = [
          {
            key: 'full_name', stickyColumn: true, label: 'Student', isEditable: false,
          },
        ]

        response.forEach(item => {
          const row = { ...item }
          item.grades.forEach(i => {
          // add student grades to the table
            row[i.grade_id] = i.grade_value
            if (i.grade_title === 'final grade') {
              tableColumns.push(
                { key: i.grade_id.toString(), label: i.grade_title, isFinalGrade: true },
              )
            } else {
              tableColumns.push(
                {
                  key: i.grade_id.toString(), label: i.grade_title, gradeType: i.grade_type, isEditable: true,
                },
              )
            }
          })
          studentsWithGrades.push(row)
        })

        this.studentsWithGrades = studentsWithGrades
        this.tableColumns = tableColumns
      } finally {
        this.isLoading = false
      }
    },
    prepareTableData() {
      this.prepareGradebookEntriesList()
      this.prepareGradebookTable()
    },
    async prepareAlphabeticalOptions() {
      const response = await this.fetchGradeMappingsList()
      this.alphabeticalOptions = response.map(item => item.value)
    },
    checkIfColumnIsNotExist(key) {
      return !this.tableColumns.some(obj => obj.key === key)
    },
    showCreateGradebookEntryModal() {
      this.gradebookModalMode = 'create'
      this.$nextTick(() => {
        this.$bvModal.show('gradebookEntryModal')
      })
    },
    showEditGradebookEntryModal(id) {
      this.gradebookModalMode = 'edit'
      // eslint-disable-next-line eqeqeq
      this.selectedGradeForEdit = this.gradebookEntriesList.find(item => item.id == id)

      this.$nextTick(() => {
        this.$bvModal.show('gradebookEntryModal')
      })
    },
    async handlesetGradeForStudent(value, gradeId, userId) {
      if (value) {
        try {
          this.isLoading = true
          const queryParams = {
            grade: value,
            grade_id: gradeId,
            user_id: userId,
          }
          await this.setGradeForStudent(queryParams).then(() => {
            this.prepareTableData()
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    handleDragEnd(event) {
      this.moveItemInArray(this.studentsWithGrades, event.oldIndex, event.newIndex)
      const newStudentPositionIds = this.studentsWithGrades
        .filter(item => item.id)
        .map(item => item.id)

      const queryParams = {
        ids: newStudentPositionIds,
        group_id: this.classId,
        subject_id: this.subjectId,
      }

      this.updateStudentsPosition(queryParams)
    },
    moveItemInArray(array, fromIndex, toIndex) {
      // Remove the item from the original position
      const [item] = array.splice(fromIndex, 1)
      // Insert the item at the new position
      array.splice(toIndex, 0, item)
    },
  },
}
</script>

<style lang="scss">
.handle-drag {
  cursor: move;
}
</style>
