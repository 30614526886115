<template>
  <b-modal
    id="editFinalGradeType"
    ref="editFinalGradeType"
    title="Edit Final Grade"
    centered
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    size="sm"
    class="p-0"
    ok-only
    @show="setModalData"
    @ok="updateFinalGradeType"
  >

    <b-form-group
      label="Mark Type"
      class="mb-2"
    >
      <v-select
        v-model="finalGradetype"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="markTypeOptions"
        :clearable="false"
        placeholder="Select Mark Type"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

export default {
  name: 'EditFinalGradeType',
  components: {
    BModal,
    BFormGroup,

    vSelect,
  },
  data() {
    return {
      finalGradetype: '',
      markTypeOptions: [
        '1-100', 'A-Z',
      ],
    }
  },
  methods: {
    setModalData() {
      this.finalGradetype = localStorage.getItem('finalGradetype') || '1-100'
    },
    updateFinalGradeType() {
      localStorage.setItem('finalGradetype', this.finalGradetype)
      this.$emit('success')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
