import { ref, computed, watch } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useCalendarEventHandler(props, clearForm, emit) {
  // Use toast
  const toast = useToast()
  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])
  const updateOnlyCurrent = ref(true)
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
  }
  watch(props.event, () => {
    resetEventLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isEventHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id) {
      if (updateOnlyCurrent.value) {
        emit('update-event', eventData.value)
      } else {
        emit('update-recurrent-event', eventData.value)
      }
    } else emit('add-event', eventData.value)

    // Close sidebar
    emit('update:is-event-handler-sidebar-active', false)
  }

  const toggleSidebar = val => {
    emit('update:is-event-handler-sidebar-active', val)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const durationOptions = [
    '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45', '03:00',
  ]

  const daysOfWeekOptions = [
    { label: 'Sunday', value: 0 },
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
  ]

  const teachersOptions = ref([])

  const fetchTeachersList = () => axios
    .get('/auth/staffs', {
      params: {
        program_id: programId.value,
      },
    })
    .then(response => {
      teachersOptions.value = response.data.data.map(item => ({ id: item.user.id, label: item.user.full_name }))
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching staff list',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  return {
    eventLocal,
    resetEventLocal,

    // UI
    durationOptions,
    daysOfWeekOptions,
    teachersOptions,
    fetchTeachersList,
    onSubmit,
    programId,
    updateOnlyCurrent,
    toggleSidebar,
  }
}
