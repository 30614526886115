<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    variant="white"
    opacity="1"
    rounded="sm"
  >
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-2 flex-sm-row flex-column">
        <div class="d-flex flex-column">
          <h3 class="d-inline-flex align-items-center">
            <feather-icon
              icon="ArrowLeftIcon"
              size="21"
              class="cursor-pointer"
              @click="backToLessonsList"
            />
            {{ selectedLesson.title }}
          </h3>
          <h5 class="d-inline-flex align-items-center ml-2 mt-1 mb-2">
            <div class="d-flex flex-column">
              <span>{{ selectedLesson.start | dateToDetailString }} </span>
              <span>{{ selectedLesson.start | dateToTime }} - {{ selectedLesson.end | dateToTime }} ({{ selectedLesson.start | dateToTZ }})</span>
            </div>
            <feather-icon
              v-if="$can('update-lesson', permissionSubjects.Academics)"
              size="21"
              icon="EditIcon"
              class="cursor-pointer text-primary ml-1"
              @click="handleEditLesson(selectedLesson)"
            />
          </h5>
        </div>
        <b-button
          v-if="$can('update-student', permissionSubjects.Academics)"
          variant="primary"
          @click="markAllAsOnTime"
        >
          <span class="mr-25 align-middle">Mark all as on time</span>
        </b-button>
      </div>

      <b-card
        no-body
        bg-variant="light"
        class="border-light"
      >
        <b-card-body>
          <h4 class="d-flex align-items-center justify-content-between w-100 pb-1 border-bottom">
            <span>
              Students
            </span>

            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="mx-auto p-0"
              no-caret
              right
            >

              <template #button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body cursor-pointer"
                />
              </template>

              <b-dropdown-item
                v-if="$can('update-student', permissionSubjects.Academics)"
                @click="resetAll"
              >
                <span>Reset</span>
              </b-dropdown-item>

            </b-dropdown>
          </h4>
          <b-row>
            <b-col
              v-for="student in studentsList"
              :key="student.id"
              sm="12"
              md="4"
            >
              <b-card
                class="student-item"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h5>{{ student.full_name }}</h5>
                  </div>
                  <b-dropdown
                    variant="link"
                    no-caret
                    right
                    :disabled="!$can('update-student', permissionSubjects.Academics)"
                  >

                    <template #button-content>
                      <b-badge
                        pill
                        :variant="`light-${resolveAttendenceStatusVariant(student.status)}`"
                      >
                        {{ resolveAttendenceStudentStatus(student.status) }}
                      </b-badge>
                    </template>
                    <b-dropdown-item
                      v-for="(status, index) in attendenceStatusOptions"
                      :key="index"
                      @click="changeStudentAttendance(student.id, status.value)"
                    >
                      <b-badge
                        pill
                        class="w-100"
                        :variant="`light-${resolveAttendenceStatusVariant(status.value)}`"
                      >
                        {{ status.label }}
                      </b-badge>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-card>

    <div
      class="body-content-overlay"
      :class="{'show': isCalendarOverlaySidebarActive}"
      @click="isCalendarOverlaySidebarActive = false"
    />
    <calendar-event-handler
      v-model="isEventHandlerSidebarActive"
      :event="event"
      :clear-event-data="clearEventData"
      @remove-event="deleteLessonAndRefetch"
      @update-event="updateLessonAndRefetch"
      @update-recurrent-event="updateRecurrentLessonsAndRefetch"
    />

  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import { filters } from '@core/mixins/filters'
import {
  BCard, BCardBody, BRow, BCol, BOverlay,
  BDropdown, BDropdownItem, BButton, BBadge,
} from 'bootstrap-vue'
import useLessonsList from './useLessonsList'
import store from '@/store'
import CalendarEventHandler
  from '@/views/admin/academics/subject-details/tabs/schedule/calendar-event-handler/CalendarEventHandler.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'LessonAttendence',
  components: {
    CalendarEventHandler,
    BOverlay,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BBadge,
  },
  mixins: [filters],
  setup() {
    const {
      fetchStudentsList,
      studentsList,
      currentClassId,
      resolveAttendenceStatusVariant,
      resolveAttendenceStudentStatus,
      attendenceStatusOptions,
      changeLessonAttendance,
      addLesson,
      updateLesson,
      updateRecurrentLessons,
      deleteLesson,

      isCalendarOverlaySidebarActive,
      isEventHandlerSidebarActive,
      event,
      clearEventData,
      handleEditLesson,
      grabEventDataFromTableRow,
    } = useLessonsList()

    return {
      fetchStudentsList,
      studentsList,
      currentClassId,
      resolveAttendenceStatusVariant,
      resolveAttendenceStudentStatus,
      attendenceStatusOptions,
      changeLessonAttendance,

      addLesson,
      updateLesson,
      updateRecurrentLessons,
      deleteLesson,

      isCalendarOverlaySidebarActive,
      isEventHandlerSidebarActive,
      event,
      clearEventData,
      handleEditLesson,
      grabEventDataFromTableRow,
    }
  },
  data() {
    return {
      permissionSubjects,
    }
  },
  computed: {
    ...mapGetters({}),
    selectedLesson: {
      get() {
        if (store.getters['app-subject-details/getLessonDetails']) {
          return store.getters['app-subject-details/getLessonDetails']
        }
        return store.getters['app-lessons/getSelectedLesson']
      },
    },
    isLoading: {
      get() {
        return store.getters['app-lessons/getIsLoading']
      },
      set(val) {
        store.commit('app-lessons/SET_IS_LOADING', val)
      },
    },
  },
  created() {
    this.prepareStudentsList()
  },
  methods: {
    async prepareStudentsList() {
      try {
        this.isLoading = true
        const queryParams = {
          lesson_id: this.selectedLesson.id,
          sortBy: 'name',
        }
        this.studentsList = await this.fetchStudentsList(queryParams)
      } finally {
        this.isLoading = false
      }
    },
    async markAllAsOnTime() {
      const queryParams = {
        lesson_id: this.selectedLesson.id,
        for_all: true,
        status: 'on time',
      }
      await this.changeLessonAttendance(queryParams)
      await this.prepareStudentsList()
    },
    async resetAll() {
      const queryParams = {
        lesson_id: this.selectedLesson.id,
        for_all: true,
        status: 'reset',
      }
      await this.changeLessonAttendance(queryParams)
      await this.prepareStudentsList()
    },
    async changeStudentAttendance(studentId, status) {
      const queryParams = {
        lesson_id: this.selectedLesson.id,
        student_id: studentId,
        for_all: false,
        status,
      }
      await this.changeLessonAttendance(queryParams)
      const student = this.studentsList.find(item => item.id === studentId)
      student.status = status
    },
    backToLessonsList() {
      store.commit('app-lessons/SET_SELECTED_LESSON', null)
      store.commit('app-subject-details/SET_LESSON_DETAILS', null)
    },
    async updateLessonAndRefetch(data) {
      const response = await this.updateLesson(data, false)
      if (store.getters['app-subject-details/getLessonDetails']) {
        store.commit('app-subject-details/SET_LESSON_DETAILS', response)
      } else {
        store.commit('app-lessons/SET_SELECTED_LESSON', response)
      }
    },
    async updateRecurrentLessonsAndRefetch(data) {
      const response = await this.updateRecurrentLessons(data, false)
      const lesson = response.find(item => item.id === this.selectedLesson.id)
      if (store.getters['app-subject-details/getLessonDetails']) {
        store.commit('app-subject-details/SET_LESSON_DETAILS', lesson)
      } else {
        store.commit('app-lessons/SET_SELECTED_LESSON', lesson)
      }
    },
    async deleteLessonAndRefetch() {
      await this.deleteLesson(this.selectedLesson.id, false)
      this.backToLessonsList()
    },
  },
}
</script>`

<style lang="scss" scoped>
  .student-item {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12) !important;
    border-radius: 8px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
</style>
